/* eslint-disable complexity */

import React, {memo} from 'react';

import {Arrow} from './windows/Arrow';
import {Frame} from './windows/Frame';
import {Glass} from './windows/Glass';
import {Handle} from './windows/Handle';
import {Hinge} from './windows/Hinge';

const GLASS_GAP = 26;
const GLASS_GAP_ALT = 11;
const STROKE_WIDTH = 2;
const PADDING = 2;

export let Window = memo(({
	width,
	height,
	color,
	type,
	variant,
	view,
	onResize,
	glassColor
}) => {
	const FRAME_GLASS_SPACE = view === 'OUTER' ? 7 * 8 : 5 * 8;
	
	let windowElements = null;
	let x = PADDING;
	let y = PADDING;
	
	let glassWidth = 0;
	let glassHeight = 0;
	
	switch (type) {
		case 'F+F':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP) * 0.5;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			windowElements =
				<>
					<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
					<Glass
						left={x + FRAME_GLASS_SPACE}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={false}
						lines={[]}
					/>
					<Glass
						left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={false}
						lines={[]}
					/>
				</>;
			break;
		
		case 'F':
			glassWidth = width - FRAME_GLASS_SPACE * 2;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			windowElements =
				<>
					<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
					<Glass
						left={x + FRAME_GLASS_SPACE}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={false}
						lines={[]}
					/>
				</>;
			break;
		
		case 'O':
			glassWidth = width - FRAME_GLASS_SPACE * 2;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT'] : ['TO_LEFT']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
								null}
						</>;
					break;
				case 2:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_LEFT'] : ['TO_RIGHT']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth}
									top={y + FRAME_GLASS_SPACE + glassHeight}
									color={color}
									type="B_RIGHT"
								/> :
								null}
						</>;
					break;
			}
			break;
		
		case 'OS':
			glassWidth = width - FRAME_GLASS_SPACE * 2;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : ['TO_LEFT', 'TO_TOP']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
								null}
						</>;
					break;
				
				case 2:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : ['TO_RIGHT', 'TO_TOP']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth}
									top={y + FRAME_GLASS_SPACE + glassHeight}
									color={color}
									type="B_RIGHT"
								/> :
								null}
						</>;
					break;
			}
			break;
		
		case 'S':
			glassWidth = width - FRAME_GLASS_SPACE * 2;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			windowElements =
				<>
					<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
					<Glass
						left={x + FRAME_GLASS_SPACE}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={true}
						lines={['TO_TOP']}
					/>
					
					{view === 'INNER' ?
						<Handle left={width / 2} top={y + FRAME_GLASS_SPACE} color={color} orientation="TO_BOTTOM" /> :
						null}
					
					{view === 'INNER' ?
						<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="C_LEFT" /> :
						null}
					{view === 'INNER' ?
						<Hinge
							left={x + FRAME_GLASS_SPACE + glassWidth}
							top={y + FRAME_GLASS_SPACE + glassHeight}
							color={color}
							type="C_RIGHT"
						/> :
						null}
				</>;
			break;
		
		case 'ST':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 + STROKE_WIDTH) * 0.5;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : ['TO_RIGHT', 'RIGHT']}
							/>
							<Glass
								left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 1}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_LEFT', 'LEFT'] : ['TO_LEFT', 'TO_TOP']}
							/>
							
							{view === 'INNER' ?
								<Handle
									left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 0.5}
									top={height / 2}
									color={color}
									orientation="TO_LEFT"
								/> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
									top={y + FRAME_GLASS_SPACE}
									color={color}
									type="A_RIGHT"
								/> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
									top={y + FRAME_GLASS_SPACE + glassHeight}
									color={color}
									type="B_RIGHT"
								/> :
								null}
						</>;
					break;
				case 2:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT', 'RIGHT'] : ['TO_RIGHT', 'TO_TOP']}
							/>
							<Glass
								left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 1}
								top={y + FRAME_GLASS_SPACE}
								width={glassWidth}
								height={glassHeight}
								color={glassColor}
								view={view}
								frame={true}
								lines={view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : ['TO_LEFT', 'LEFT']}
							/>
							
							{view === 'INNER' ?
								<Handle
									left={x + FRAME_GLASS_SPACE + glassWidth - STROKE_WIDTH * 0.5}
									color={color}
									top={height / 2}
									orientation="TO_RIGHT"
								/> :
								null}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
									top={y + FRAME_GLASS_SPACE}
									color={color}
									type="A_RIGHT"
								/> :
								null}
							{view === 'INNER' ?
								<Hinge
									left={x + FRAME_GLASS_SPACE + glassWidth * 2 - STROKE_WIDTH * 1}
									top={y + FRAME_GLASS_SPACE + glassHeight}
									color={color}
									type="B_RIGHT"
								/> :
								null}
						</>;
					break;
			}
			break;
		
		case 'OS+F':
		case 'PSK-TROCAL70':
		case 'PSK-TROCAL76+':
		case 'PSK-SOFTLINE82':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP_ALT) * 0.5;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			let linesFirst = [];
			let linesSecond = [];
			
			let handleLeft = 0;
			let handleOrientation = '';
			
			let hasHinge = true;
			let hasArrow = false;
			if (type === 'PSK-TROCAL70' || type === 'PSK-TROCAL76+' || type === 'PSK-SOFTLINE82') {
				hasHinge = false;
				hasArrow = true;
			}
			
			switch (variant) {
				case 1:
					if (type === 'PSK-TROCAL70' || type === 'PSK-TROCAL76+' || type === 'PSK-SOFTLINE82') {
						linesFirst = view === 'INNER' ? ['TO_TOP'] : [];
						linesSecond = view === 'INNER' ? [] : ['TO_TOP'];
						handleLeft = x + FRAME_GLASS_SPACE;
						handleOrientation = 'SIMPLE';
					} else {
						linesFirst = view === 'INNER' ? ['TO_RIGHT', 'TO_TOP'] : [];
						linesSecond = view === 'INNER' ? [] : ['TO_RIGHT', 'TO_TOP'];
						handleLeft = x + FRAME_GLASS_SPACE + glassWidth;
						handleOrientation = 'TO_LEFT';
					}
					
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									
									<Glass left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view === 'INNER'} lines={linesFirst} />
									<Glass left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view !== 'INNER'} lines={linesSecond} />
									
									{<Handle left={handleLeft} top={height / 2} color={color} orientation={handleOrientation} />}
									
									{hasArrow ? <Arrow left={x + FRAME_GLASS_SPACE + glassWidth / 2} orientation="TO_RIGHT" view={view}></Arrow> : null}
									
									{hasHinge ? <Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> : null}
									{hasHinge ? <Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> : null}
								</>;
							break;
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									
									<Glass left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view === 'INNER'} lines={linesFirst} />
									<Glass left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view !== 'INNER'} lines={linesSecond} />
									
									{hasArrow ? <Arrow left={x + FRAME_GLASS_SPACE + glassWidth * 1.5} orientation="TO_LEFT" view={view}></Arrow> : null}
								</>;
							break;
					}
					break;
				
				case 2:
					if (type === 'PSK-TROCAL70' || type === 'PSK-TROCAL76+' || type === 'PSK-SOFTLINE82') {
						linesFirst = view === 'INNER' ? [] : ['TO_TOP'];
						linesSecond = view === 'INNER' ? ['TO_TOP'] : [];
						handleLeft = x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT - 50;
						handleOrientation = 'SIMPLE';
					} else {
						linesFirst = view === 'INNER' ? [] : ['TO_RIGHT', 'TO_TOP'];
						linesSecond = view === 'INNER' ? ['TO_LEFT', 'TO_TOP'] : [];
						handleLeft = x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT;
						handleOrientation = 'TO_RIGHT';
					}
					
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									
									<Glass left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view !== 'INNER'} lines={linesFirst} />
									<Glass left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view === 'INNER'} lines={linesSecond} />
									
									{<Handle left={handleLeft} top={height / 2} color={color} orientation={handleOrientation} />}
									
									{hasArrow ? <Arrow left={x + FRAME_GLASS_SPACE + glassWidth * 1.5 + GLASS_GAP_ALT} orientation="TO_LEFT" view={view}></Arrow> : null}
									
									{hasHinge ?
										<Hinge left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> : null}
									{hasHinge ?
										<Hinge left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_RIGHT" /> : null}
								</>;
							break;
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									
									<Glass left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view !== 'INNER'} lines={linesFirst} />
									<Glass left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT} top={y + FRAME_GLASS_SPACE} width={glassWidth} height={glassHeight} color={glassColor} view={view} frame={view === 'INNER'} lines={linesSecond} />
									
									{hasArrow ? <Arrow left={x + FRAME_GLASS_SPACE + glassWidth / 2 + GLASS_GAP_ALT} orientation="TO_RIGHT" view={view}></Arrow> : null}
								</>;
							break;
					}
					break;
			}
			break;
		
		case 'OS+OS':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP) * 0.5;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			windowElements =
				<>
					<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
					<Glass
						left={x + FRAME_GLASS_SPACE}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={true}
						lines={['TO_RIGHT', 'TO_TOP']}
					/>
					<Glass
						left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
						top={y + FRAME_GLASS_SPACE}
						width={glassWidth}
						height={glassHeight}
						color={glassColor}
						view={view}
						frame={true}
						lines={['TO_LEFT', 'TO_TOP']}
					/>
					
					{view === 'INNER' ?
						<Handle
							left={x + FRAME_GLASS_SPACE + glassWidth - 22}
							top={height / 2 - 28}
							color={color}
							orientation="TO_BOTTOM"
						/> :
						null}
					{view === 'INNER' ?
						<Handle
							left={x + FRAME_GLASS_SPACE + GLASS_GAP + glassWidth + 22}
							top={height / 2 - 28}
							color={color}
							orientation="TO_BOTTOM"
						/> :
						null}
					
					{view === 'INNER' ?
						<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" /> : null}
					{view === 'INNER' ?
						<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" /> : null}
					{view === 'INNER' ?
						<Hinge left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP} top={y + FRAME_GLASS_SPACE} color={color} type="A_RIGHT" /> : null}
					{view === 'INNER' ?
						<Hinge left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_RIGHT" /> : null}
				</>;
			break;
		
		case 'OS+OS+OS':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP - GLASS_GAP_ALT) * (1 / 3);
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth - 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP - 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT + 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
									
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_LEFT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_LEFT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
								</>;
							break;
						
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
								</>;
							break;
					}
					break;
				
				case 2:
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth - 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT + 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP + 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
									
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
								</>;
							break;
						
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
								</>;
							break;
					}
					break;
			}
			break;
		
		case 'ST+OS':
			glassWidth = (width - FRAME_GLASS_SPACE * 2 - GLASS_GAP - GLASS_GAP_ALT) * (1 / 3);
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth - 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP + 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
									
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
								</>;
							break;
						
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
								</>;
							break;
					}
					break;
				
				case 2:
					switch (view) {
						case 'INNER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										view={view}
										frame={true}
										lines={['TO_RIGHT']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
									
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth - 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									<Handle
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP + GLASS_GAP_ALT + 22}
										top={height / 2 - 28}
										color={color}
										orientation="TO_BOTTOM"
									/>
									
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="A_LEFT" />
									<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="B_LEFT" />
									
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_LEFT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_LEFT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										color={color}
										type="A_RIGHT"
									/>
									<Hinge
										left={x + FRAME_GLASS_SPACE + glassWidth * 3 + GLASS_GAP + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE + glassHeight}
										color={color}
										type="B_RIGHT"
									/>
								</>;
							break;
						
						case 'OUTER':
							windowElements =
								<>
									<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
									<Glass
										left={x + FRAME_GLASS_SPACE}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_RIGHT', 'TO_TOP']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth + GLASS_GAP_ALT}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT']}
									/>
									<Glass
										left={x + FRAME_GLASS_SPACE + glassWidth * 2 + GLASS_GAP_ALT + GLASS_GAP}
										top={y + FRAME_GLASS_SPACE}
										width={glassWidth}
										height={glassHeight}
										color={glassColor}
										view={view}
										frame={true}
										lines={['TO_LEFT', 'TO_TOP']}
									/>
								</>;
							break;
					}
					break;
			}
			break;
		
		case 'TD-TROCAL':
		case 'TD-SOFTLINE70':
		case 'TD-SOFTLINE82':
		case 'VD-TROCAL':
		case 'VD-SOFTLINE70':
		case 'VD-SOFTLINE82':
		case 'TD-TROCAL76+':
			glassWidth = width - FRAME_GLASS_SPACE * 2;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE}
								width={glassWidth} height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT'] : ['TO_LEFT']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" /> :
								<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" />
							}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="D_LEFT_TOP" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight / 2} color={color} type="D_LEFT_MIDDLE" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="D_LEFT_BOTTOM" /> : null}
						</>;
					break;
				
				case 2:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE}
								width={glassWidth} height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
								lines={view === 'INNER' ? ['TO_LEFT'] : ['TO_RIGHT']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE} top={height / 2} color={color} orientation="TO_RIGHT" /> :
								<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_LEFT" />
							}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE} color={color} type="D_RIGHT_TOP" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE + glassHeight / 2} color={color} type="D_RIGHT_MIDDLE" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="D_RIGHT_BOTTOM" /> : null}
						</>;
					break;
			}
			break;
		
		case 'VD-TROCAL76+FIX':
		case 'VD-SOFTLINE82+FIX':
			let doorWidth = 900;
			glassWidth = width - FRAME_GLASS_SPACE * 2 - doorWidth;
			glassHeight = height - FRAME_GLASS_SPACE * 2;
			
			switch (variant) {
				case 1:
					let glassLeft = view === 'INNER' ? x + FRAME_GLASS_SPACE : x + FRAME_GLASS_SPACE + glassWidth;
					let glassLeft2 = view === 'INNER' ? x + FRAME_GLASS_SPACE * 2 + doorWidth : x;
					
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={glassLeft} top={y + FRAME_GLASS_SPACE}
								width={doorWidth} height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
								lines={view === 'INNER' ? ['TO_RIGHT'] : ['TO_LEFT']}
							/>
							<Glass
								left={glassLeft2} top={y}
								width={glassWidth} height={glassHeight + FRAME_GLASS_SPACE * 2 - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE + doorWidth} top={height / 2} color={color} orientation="TO_LEFT" /> : null
							}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE} color={color} type="D_LEFT_TOP" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight / 2} color={color} type="D_LEFT_MIDDLE" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="D_LEFT_BOTTOM" /> : null}
						</>;
					break;
				case 2:
					windowElements =
						<>
							<Frame onResize={onResize} left={x} top={y} width={width} height={height} color={color} />
							<Glass
								left={view === 'INNER' ? x : x + FRAME_GLASS_SPACE * 2 + doorWidth} top={y}
								width={glassWidth} height={glassHeight + FRAME_GLASS_SPACE * 2 - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
							/>
							<Glass
								left={view === 'INNER' ? x + FRAME_GLASS_SPACE + glassWidth : x + FRAME_GLASS_SPACE} top={y + FRAME_GLASS_SPACE}
								width={doorWidth} height={glassHeight + FRAME_GLASS_SPACE - STROKE_WIDTH * 4}
								color={glassColor} view={view} frame={true}
								lines={view === 'INNER' ? ['TO_LEFT'] : ['TO_RIGHT']}
							/>
							
							{view === 'INNER' ?
								<Handle left={x + FRAME_GLASS_SPACE + glassWidth} top={height / 2} color={color} orientation="TO_RIGHT" /> : null
							}
							
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth + doorWidth} top={y + FRAME_GLASS_SPACE} color={color} type="D_RIGHT_TOP" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth + doorWidth} top={y + FRAME_GLASS_SPACE + glassHeight / 2} color={color} type="D_RIGHT_MIDDLE" /> : null}
							{view === 'INNER' ?
								<Hinge left={x + FRAME_GLASS_SPACE + glassWidth + doorWidth} top={y + FRAME_GLASS_SPACE + glassHeight} color={color} type="D_RIGHT_BOTTOM" /> : null}
						</>;
					break;
			}
			break;
	}
	
	let svgStyle = `
	path, line, rect {
		vector-effect: non-scaling-stroke;
	}
	text {
		font-family: Arial;
		font-size: 20px;
	}
	`;
	
	return (
		<svg
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox={`0 0 ${width + 2 * PADDING} ${height + 2 * PADDING}`}
		>
			<style>{svgStyle}</style>
			{windowElements}
		</svg>
	);
});

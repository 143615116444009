export default function findDefaultGlass(glasses, profileId) {
	let result = glasses.filter((glass) => glass.profileIds.includes(profileId) && glass.price === 0);
	if (result[0]) {
		let glass = result[0];
		let glassPrice = glass.price;
		if (glass.prices) {
			for (let priceObj of glass.prices) {
				if (priceObj.profileId === profileId) {
					glassPrice = priceObj.price;
				}
			}
		}
		
		return {
			id: glass.id,
			name: glass.name,
			price: glassPrice
		};
	}
	
	return null;
}

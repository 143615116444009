/* eslint-disable complexity */

import $ from 'jquery';
import _ from 'lodash';
import React, {memo, useCallback} from 'react';
import config from '../config';
import {useStream} from '../hooks/useStream';
import I18n from '../libs/I18n';
import createOrderItemActionStream from '../streams/createOrderItemActionStream';
import dataStream from '../streams/dataStream';
import destroyOrderItemActionStream from '../streams/destroyOrderItemActionStream';
import languageStream from '../streams/languageStream';
import openStepActionStream from '../streams/openStepActionStream';
import orderStream from '../streams/orderStream';
// import {WindowType, WindowSize} from '../types/data';
import selectColorActionStream from '../streams/selectColorActionStream';
import selectMaterialActionStream from '../streams/selectMaterialActionStream';
import selectProfileActionStream from '../streams/selectProfileActionStream';
// import Stream from '../libs/Stream';
import selectWindowActionStream from '../streams/selectWindowActionStream';
import findDefaultColor from '../utils/findDefaultColor';
import findDefaultGlass from '../utils/findDefaultGlass';
import findDefaultMaterial from '../utils/findDefaultMaterial';
import findDefaultProfile from '../utils/findDefaultProfile';
import findProfileWithinColor from '../utils/findProfileWithinColor';
import getConvertedPrice from '../utils/getConvertedPrice';
// import link from '../utils/link';
import getOrderItemPrice from '../utils/getOrderItemPrice';
import Button from './Button';
import ButtonGroup from './ButtonGroup';
import Icon from './Icon';
import Input from './Input';
import {Window} from './Window';

import styles from './WindowStep.css';

const VAT_MODIFIER = config.VAT_MODIFIER; // eslint-disable-line prefer-destructuring
const ALT_NAME_HEIGHT = config.ALT_NAME_HEIGHT; // eslint-disable-line prefer-destructuring
const NOT_DIGIT_REGEX = /\D/;

let i18n = new I18n();

/* export interface WindowDetailProps {
	variant: 1 | 2;
	width: number;
	height: number;
	windowType: WindowType;
	windowSize: WindowSize;
	isRecommended: boolean;
	isSelected: boolean;
	handleClick: (windowType: WindowType, windowSize: WindowSize, variant: number) => void;
} */

export let WindowDetail = memo(
	({
		selectedVariant,
		width,
		height,
		materialId,
		windowType,
		windowSize,
		isRecommended,
		isSelected,
		handleClick
	}) => {
		useStream(languageStream);
		
		let order = useStream(orderStream);
		let orderItem;
		
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}
		
		if (!orderItem) {
			return null;
		}
		
		let handleVariant1Click = useCallback(
			(event) => {
				if (handleClick && event.nativeEvent.variant2Handled !== true) {
					handleClick(windowType, windowSize, 1, orderItem.color);
				}
			},
			[handleClick, windowType, windowSize, orderItem.color]
		);
		
		let handleVariant2Click = useCallback(
			(event) => {
				// eslint-disable-next-line no-param-reassign
				event.nativeEvent.variant2Handled = true;
				
				if (handleClick) {
					handleClick(windowType, windowSize, 2, orderItem.color);
				}
			},
			[handleClick, windowType, windowSize, orderItem.color]
		);
		
		//	let isSelected = orderItem?.window?.typeId === windowType?.id;
		//	let selectedVariant = orderItem?.window?.variant;
		let {
			materials,
			profiles,
			glasses,
			colors
		} = dataStream.value;
		let defaultMaterial = materialId || !orderItem.material ? findDefaultMaterial(materials, materialId) : orderItem.material;
		let defaultProfile = windowType.profileId || !orderItem.profile ? findDefaultProfile(profiles, windowType, defaultMaterial.id) : orderItem.profile;
		let defaultGlass = orderItem.glass || findDefaultGlass(glasses, defaultProfile.id);
		let defaultColor = orderItem.color || findDefaultColor(colors, defaultMaterial.id);
		// console.log('windowstep - defaults', [defaultMaterial, defaultProfile, defaultGlass, defaultColor]);
		
		let tempOrderItem = _.assign(
			_.cloneDeep(orderItem),
			{
				window: {
					typeId: windowType.id,
					sizeId: windowSize.id,
					materialId,
					profileId: windowType.profileId,
					type: windowType.type,
					width,
					height,
					price: windowSize.price,
					variant: 1
				}
			},
			{material: defaultMaterial},
			{profile: defaultProfile},
			{glass: defaultGlass},
			{color: defaultColor}
		);
		
		let {windowPrice: price} = getOrderItemPrice(tempOrderItem);
		
		// check if profile is available for the color
		let showResetColorWarning = false;
		
		if (orderItem.color && windowType.profileId ? !findProfileWithinColor(orderItem.color, windowType.profileId) : false) {
			showResetColorWarning = true;
		}
		
		return (
			<div className={
				styles.windowDetail +
					(isRecommended ? ` ${styles.isRecommended}` : '') +
					(isSelected ? ` ${styles.isSelected}` : '') +
					(windowType.hasLeftRightVariants ? ` ${styles.hasLeftRightVariants}` : '')
			}
			onClick={handleVariant1Click}
			>
				<h4 className={styles.windowDetailHeading}>
					{windowType.altName && height >= ALT_NAME_HEIGHT ?
						windowType.altName[languageStream.value.current] :
						windowType.name[languageStream.value.current]}{' '}
					{width}x{height} mm
				</h4>
				
				<figure className={styles.windowDetailPhoto}>
					<Window
						width={orderItem.window ? orderItem.window.width : width}
						height={orderItem.window ? orderItem.window.height : height}
						color={'#ffffff'}
						type={windowType.type}
						variant={isSelected ? selectedVariant || 1 : 1}
						view="INNER"
					/>
				</figure>
				
				{showResetColorWarning ?
					<span className={styles.windowDetailColorWarning}>{i18n.translate `WindowStep - window not available in color`}</span> : null}
				{windowType.hasLeftRightVariants ?
					<div className={styles.windowDetailVariants}>
						<p className={styles.windowDetailVariantsHeading}>{i18n.translate `windowType - variant`}</p>
						<p className={styles.windowDetailVariantsList}>
							<span className={styles.windowDetailVariant} onClick={handleVariant1Click}>
								<span className={styles.windowDetailVariantBox + (isSelected && selectedVariant === 1 ? ` ${styles.isSelected}` : '')}>
									<Icon id="cross" size="small" />
								</span>
								<span>{i18n.translate `windowType - variant - left`}</span>
							</span>
							<span className={styles.windowDetailVariant} onClick={handleVariant2Click}>
								<span className={styles.windowDetailVariantBox + (isSelected && selectedVariant === 2 ? ` ${styles.isSelected}` : '')}>
									<Icon id="cross" size="small" />
								</span>
								<span>{i18n.translate `windowType - variant - right`}</span>
							</span>
						</p>
					</div> :
					null}
				
				<p className={styles.windowDetailPrice}>
					{i18n.translate `WindowStep - window detail - price - ${getConvertedPrice(price * VAT_MODIFIER[languageStream.value.current])}:c`}
				</p>
				{isRecommended ? <p className={styles.windowDetailLabel}>{i18n.translate `WindowStep - window detail - recommended`}</p> : null}
			</div>
		);
	},
	(prevProps, nextProps) => nextProps.handleClick === prevProps.handleClick &&
		nextProps.width === prevProps.width &&
		nextProps.height === prevProps.height &&
		nextProps.materialId === prevProps.materialId &&
		nextProps.windowType.id === prevProps.windowType.id &&
		nextProps.windowSize.id === prevProps.windowSize.id &&
		nextProps.selectedVariant === prevProps.selectedVariant &&
		nextProps.isRecommended === prevProps.isRecommended &&
		nextProps.isSelected === prevProps.isSelected
);

/* export interface WindowStepProps {
	isOpen: boolean;
}

export interface WindowStepState {
	width: number;
	height: number;
}*/

export default class WindowStep extends React.Component<WindowStepProps, WindowStepState> {
	state = {
		width: 0,
		height: 0,
		number: 1,
		materialId: 0,
		available: true
	};
	
	onLanguageStream: Stream<{}>;
	onOrderStream: Stream<{}>;
	
	constructor(props) {
		super(props);
		
		this.rootRef = React.createRef();
	}
	
	// componentDidUpdate() {
	// 	// if (this.props.materialModal) {
	// 	// 	this.setState({ number: 2 });
	// 	// 	this.props.setMaterialModal(false);
	// 	// }
	// 	console.log('componentDidUpdate');
	// }
	
	updateStateFromOrder = () => {
		let order = orderStream.value;
		let orderItem;
		
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
			// console.log(orderItem);
			
			if (orderItem && orderItem.window && _.isFinite(orderItem.window.width) && _.isFinite(orderItem.window.height)) {
				if (!orderItem.material) {
					this.setState({
						width: orderItem.window.width,
						height: orderItem.window.height,
						number: 2
					});
				} else {
					this.setState({
						width: orderItem.window.width,
						height: orderItem.window.height,
						number: 2,
						materialId: orderItem.material.id
					});
				}
			} else if (!orderItem || !orderItem.window) {
				this.setState({
					width: this.state.width,
					height: this.state.height,
					number: 1
				});
			}
		}
	};
	
	componentDidMount() {
		this.onLanguageStream = languageStream.on(() => {
			// requestAnimationFrame(() => {
			this.forceUpdate();
			// });
		});
		
		this.onOrderStream = orderStream.on(() => {
			requestAnimationFrame(this.updateStateFromOrder);
		});
		
		if (orderStream.hasValue) {
			this.updateStateFromOrder();
		}
	}
	
	componentWillUnmount() {
		this.onLanguageStream.end.push(true);
		this.onOrderStream.end.push(true);
	}
	
	render() {
		let {
			windowTypes,
			windowPrices,
			windowRecommendations
		} = dataStream.value;
		let order = orderStream.value;
		let orderItem;
		if (order.currentItem !== null) {
			orderItem = order.items[order.currentItem];
		}
		if (!orderItem?.material) { return <></>; }
		
		let filteredWindowPrice = windowPrices.find(
			(windowPrice) => orderItem?.material?.id === windowPrice.materialId
		);
		
		let filteredWindowSizes = [];
		
		if (filteredWindowPrice) {
			filteredWindowSizes = filteredWindowPrice.sizes.filter(
				(windowSize) => this.state.width >= windowSize.widths[0] &&
					this.state.width <= windowSize.widths[1] &&
					this.state.height >= windowSize.heights[0] &&
					this.state.height <= windowSize.heights[1]
			);
		} else {
			for (const item of windowPrices) {
				filteredWindowSizes = filteredWindowSizes.concat(item.sizes);
			}
		}
		
		let filteredWindowRecommendations = windowRecommendations.filter(
			(windowRecommendation) => this.state.width >= windowRecommendation.widths[0] &&
				this.state.width <= windowRecommendation.widths[1] &&
				this.state.height >= windowRecommendation.heights[0] &&
				this.state.height <= windowRecommendation.heights[1]
		);
		
		
		let filteredWindowTypes = filteredWindowSizes.map((windowSize) => _.find(windowTypes, {type: windowSize.type}));
		let minWidth = filteredWindowPrice && Math.min(...filteredWindowPrice.sizes.map((windowSize) => windowSize.widths[0]));
		let maxWidth = filteredWindowPrice && Math.max(...filteredWindowPrice.sizes.map((windowSize) => windowSize.widths[1]));
		let minHeight = filteredWindowPrice && Math.min(...filteredWindowPrice.sizes.map((windowSize) => windowSize.heights[0]));
		let maxHeight = filteredWindowPrice && Math.max(...filteredWindowPrice.sizes.map((windowSize) => windowSize.heights[1]));
		
		let modal;
		switch (this.state.number) {
			case 1:
				modal =
					<div className={styles.windowSize}>
						<div className={styles.windowSizeWrapper}>
							<div className={styles.window}>
								<div className={styles.windowGlass}>
									<div className={styles.windowGlassHighlight1}></div>
									<div className={styles.windowGlassHighlight2}></div>
									<div className={styles.windowGlassHighlight3}></div>
									<div className={styles.windowGlassHighlight4}></div>
								</div>
								<div className={styles.windowHeight}>
									<label htmlFor="height">{i18n.translate `WindowStep - height`}</label>
									<Input
										type="number"
										name="height"
										min={1}
										max={9999}
										unit="mm"
										value={this.state.height}
										handleChange={this.handleHeightChange}
									/>
								</div>
								<div className={styles.windowWidth}>
									<label htmlFor="width">{i18n.translate `WindowStep - width`}</label>
									<Input
										label={i18n.translate `WindowStep - width`}
										type="number"
										name="width"
										min={1}
										max={9999}
										unit="mm"
										value={this.state.width}
										handleChange={this.handleWidthChange}
									/>
								</div>
							</div>
							
							<p>{i18n.translate `WindowStep - button 1 disabled overlay - ${minWidth}:n ${maxWidth}:n ${minHeight}:n ${maxHeight}:n`} </p>
							<p className={styles.available}>{this.state.available ? '' : i18n.translate `WindowStep - button 1 - no available`} </p>
						</div>
						
						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate `WindowStep - button 1`}
								overlayDisabledLabel={i18n.translate `WindowStep - button 1 disabled overlay - ${minWidth}:n ${maxWidth}:n ${minHeight}:n ${maxHeight}:n`}
								isDisabled={!this.checkCorrectSize(minWidth, maxWidth, minHeight, maxHeight)}
								handleClick={this.handleSaveClick}
							/>
						</ButtonGroup>
					</div>;
				break;
			case 2:
				modal =
					<div className={styles.windowType}>
						<div className={styles.windowTypeWrapper}>
							<div className={styles.windowTypesListWrapper}>
								<p className={styles.windowTypesListScrollLeft}>
									<Button type="floating" icon="left-arrow" handleClick={this.handleLeftScrollClick} />
								</p>
								<p className={styles.windowTypesListScrollRight}>
									<Button type="floating" icon="right-arrow" handleClick={this.handleRightScrollClick} />
								</p>
								<ul className={styles.windowTypesList}>
									{filteredWindowSizes.map((windowSize, windowSizeIndex) => <li key={windowSizeIndex}>
										<WindowDetail
											isRecommended={filteredWindowRecommendations[0] && filteredWindowRecommendations[0].types.includes(windowSize.type)}
											windowType={filteredWindowTypes[windowSizeIndex]}
											windowSize={windowSize}
											width={this.state.width}
											height={this.state.height}
											materialId={orderItem?.material?.id}
											handleClick={this.handleSelectWindow}
											isSelected={orderItem?.window?.typeId === filteredWindowTypes[windowSizeIndex].id}
											selectedVariant={orderItem?.window?.variant}
										/>
									</li>
									)}
								</ul>
								{orderItem?.material?.id === 1 ? <p className={styles.warning}>{i18n.translate `WindowStep - warning`}</p> : ''}
								<p className={styles.warning}>{i18n.translate `WindowStep - info 1`}</p>
							</div>
						</div>
						
						<ButtonGroup align="center">
							<Button
								type="flat"
								size="large"
								label={i18n.translate `WindowStep - button 2`}
								handleClick={this.handleBackSizes}
							/>
							<Button
								type="flat"
								size="large"
								label={i18n.translate `WindowStep - button 5`}
								isDisabled={!(orderItem && orderItem.window && orderItem.window.variant)}
								handleClick={this.handleCloseClick}
							/>
						</ButtonGroup>
					</div>;
				break;
		}
		
		return (
			<section ref={this.rootRef} className={styles.root + (this.props && this.props.isOpen ? ` ${styles.isOpen}` : '')}>
				<div className={styles.wrapper}>
					<h2 className={styles.heading}>
						{this.state.number === 1 && i18n.translate `WindowStep - heading 1`}
						{this.state.number === 2 && i18n.translate `WindowStep - heading 3`}
					</h2>
					<span className={styles.closeButton}>
						<Button type="invisible" size="large" icon="cross" handleClick={this.handleCloseClick} />
					</span>
					
					{modal}
				</div>
			</section>
		);
	}
	
	checkCorrectSize = (minWidth, maxWidth, minHeight, maxHeight) => {
		if (this.state.width && this.state.height) {
			return minWidth <= this.state.width && this.state.width <= maxWidth && minHeight <= this.state.height && this.state.height <= maxHeight;
		}
		
		return false;
	};
	
	
	handleLeftScrollClick = () => {
		if (this.rootRef && this.rootRef.current) {
			$(this.rootRef.current)
				.find(`.${styles.windowTypesList}`)[0].scrollLeft -= 100;
		}
	};
	
	handleRightScrollClick = () => {
		if (this.rootRef && this.rootRef.current) {
			$(this.rootRef.current)
				.find(`.${styles.windowTypesList}`)[0].scrollLeft += 100;
		}
	};
	
	handleCloseClick = () => {
		openStepActionStream.push(null);
	};
	
	isSelectedMaterial = (stateMaterialId, globalMaterialId, actualMaterialId) => {
		if (stateMaterialId !== 0) {
			return stateMaterialId === actualMaterialId;
		} else if (globalMaterialId) {
			return globalMaterialId === actualMaterialId;
		}
		
		return false;
	};
	
	handleWidthChange = (value) => {
		if (!value) {
			this.setState({width: 0});
			
			return;
		}
		
		let width = parseInt(value.replace(NOT_DIGIT_REGEX, ''), 10);
		if (!_.isFinite(width)) {
			return;
		}
		
		this.setState({
			width,
			available: true
		});
	};
	
	handleHeightChange = (value) => {
		if (!value) {
			this.setState({height: 0});
			
			return;
		}
		
		let height = parseInt(value.replace(NOT_DIGIT_REGEX, ''), 10);
		if (!_.isFinite(height)) {
			return;
		}
		
		this.setState({
			height,
			available: true
		});
	};
	
	
	handleBackSizes = () => {
		this.setState({number: 1});
	};
	
	/* handleBackMaterials = () => {
		this.setState({number: 2});
	};
	
	handleSelectMaterial = (material) => {
		this.setState({materialId: material.id});
	};
	*/
	
	handleSaveClick = () => {
		// console.log('handleSaveClick', [this.state.width, this.state.height, this.state.width && this.state.height])
		if (!(this.state.width && this.state.height)) {
			return;
		}
		
		let order = orderStream.value;
		let orderItem = order.items[order.currentItem];
		
		let {materials} = dataStream.value;
		const selectedMaterial = materials.find((material) => material.id === orderItem.material.id);
		selectMaterialActionStream.push({
			id: selectedMaterial.id,
			name: selectedMaterial.name
		});
		
		let {profiles} = dataStream.value;
		const selectedProfile = profiles.find((profile) => profile.materialIds.includes(selectedMaterial.id));
		selectProfileActionStream.push({
			id: selectedProfile.id,
			name: selectedProfile.name,
			priceModifier: selectedProfile.priceModifier,
			rebate: selectedProfile.rebate
		});
		
		let {
			windowTypes,
			windowPrices,
			windowRecommendations
		} = dataStream.value;
		let filteredWindowPrice = windowPrices.find(
			(windowPrice) => selectedMaterial.id === windowPrice.materialId
		);
		
		let filteredWindowSizes = [];
		if (filteredWindowPrice) {
			filteredWindowSizes = filteredWindowPrice.sizes.filter(
				(windowSize) => this.state.width >= windowSize.widths[0] &&
					this.state.width <= windowSize.widths[1] &&
					this.state.height >= windowSize.heights[0] &&
					this.state.height <= windowSize.heights[1]
			);
		}
		
		let filteredWindowRecommendations = windowRecommendations.filter(
			(windowRecommendation) => this.state.width >= windowRecommendation.widths[0] &&
				this.state.width <= windowRecommendation.widths[1] &&
				this.state.height >= windowRecommendation.heights[0] &&
				this.state.height <= windowRecommendation.heights[1]
		);
		
		let filteredWindowTypes = filteredWindowSizes.map((windowSize) => _.find(windowTypes, {type: windowSize.type}));
		
		// console.log('filtered', [filteredWindowSizes, filteredWindowTypes, filteredWindowRecommendations]);
		let available = true;
		if (filteredWindowRecommendations.length > 0) {
			let fws = filteredWindowSizes.some((windowSize, windowSizeIndex) => {
				if (filteredWindowRecommendations[0].types.includes(windowSize.type)) {
					this.handleSelectWindow(filteredWindowTypes[windowSizeIndex], windowSize, 1);
					
					return true;
				}
				
				return false;
			});
			
			// console.log('fws', fws);
			if (fws === false) {
				if (filteredWindowTypes.length > 0 && filteredWindowSizes.length > 0) {
					this.handleSelectWindow(filteredWindowTypes[0], filteredWindowSizes[0], 1);
				} else {
					let {material} = orderItem;
					destroyOrderItemActionStream.push(order.currentItem);
					createOrderItemActionStream.push(true);
					selectMaterialActionStream.push({
						id: material.id,
						name: material.name
					});
					available = false;
				}
			}
		} else {
			available = false;
		}
		
		// console.log('available', available);
		if (available === true) {
			this.setState({number: 2});
		} else {
			this.setState({
				number: 1,
				available,
				width: this.state.width,
				height: this.state.height
			});
		}
	};
	
	handleSelectWindow = (windowType, windowSize, variant, currentColor) => {
		let order = orderStream.value;
		let orderItem = order?.items[order.currentItem];
		
		selectWindowActionStream.push({
			typeId: windowType.id,
			sizeId: windowSize.id,
			materialId: orderItem?.material?.id,
			profileId: windowType.profileId,
			type: windowType.type,
			width: this.state.width,
			height: this.state.height,
			price: windowSize.price,
			hasLeftRightVariants: windowType.hasLeftRightVariants,
			variant
		});
		
		// reset color
		let {colors} = dataStream.value;
		if (currentColor && windowType.profileId ? !findProfileWithinColor(currentColor, windowType.profileId) : false) {
			selectColorActionStream.push(findDefaultColor(colors, orderItem?.material?.id));
		}
	};
}

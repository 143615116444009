import React, {memo} from 'react';
import config from '../../config';
import {useStream} from '../../hooks/useStream';
import I18n from '../../libs/I18n';
import languageStream from '../../streams/languageStream';
import orderStream from '../../streams/orderStream';
import getConvertedPrice from '../../utils/getConvertedPrice';
import Meta from './Meta';

let i18n = new I18n();
const {VAT_MODIFIER} = config;

export let Schema = memo(() => {
	let data = {};
	let price = null;
	let meta = new Meta();
	
	const order = useStream(orderStream);
	const orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	if (orderItem) {
		price = getConvertedPrice(orderItem.price ? orderItem.price * VAT_MODIFIER[languageStream.value.current] : 0);
		
		data = {
			'@context': 'https://schema.org/',
			'@type': 'Product',
			name: meta.title,
			image: meta.image,
			description: meta.description,
			brand: {
				'@type': 'Brand',
				name: 'Okna Hned'
			},
			manufacturer: 'Trocal',
			width: meta.width,
			height: meta.height,
			material: 'PVC',
			color: 'Bílá',
			additionalProperty: {
				'@type': 'PropertyValue',
				name: 'Otevírání',
				value: meta.opening
			},
			offers: {
				'@type': 'Offer',
				url: window.location.href,
				price,
				priceCurrency: i18n.currency
			}
		};
	}
	
	return (
		<script type="application/ld+json">{orderItem && price ? JSON.stringify(data) : null}</script>
	);
});

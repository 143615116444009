import React from 'react';


export let Handle = ({
	left,
	top,
	color,
	orientation
}) => {
	switch (orientation) {
		case 'TO_LEFT':
			return <g transform={`translate(${left},${top}) translate(-98.509, -13.43)`}>
				<path fill={color} d="M73.572,1.067c-14.307,0-20.165,5.544-20.165,12.381c0,6.838,5.858,12.381,20.165,12.381c14.307,0,22.94-5.244,22.94-12.083C96.511,6.909,87.878,1.067,73.572,1.067z" />
				<path fill="#231F20" d="M73.572,2.094C71.321,2,69.061,2.02,66.828,2.279c-2.23,0.247-4.46,0.689-6.538,1.566c-2.056,0.878-4,2.218-5.246,4.135c-1.272,1.891-1.763,4.266-1.516,6.528c0.231,2.268,1.243,4.444,2.874,6.017c1.625,1.582,3.698,2.614,5.842,3.282c4.337,1.301,8.944,1.28,13.422,0.907c4.471-0.383,8.917-1.363,12.731-3.249c1.889-0.947,3.577-2.198,4.726-3.681c1.154-1.489,1.586-3.23,1.313-4.95c-0.276-1.699-1.283-3.316-2.728-4.664c-1.442-1.358-3.266-2.457-5.222-3.334C82.544,3.071,78.057,2.297,73.572,2.094z M73.572,0.04c4.672-0.172,9.45,0.176,14.117,1.656c2.317,0.772,4.604,1.876,6.612,3.56c0.993,0.85,1.895,1.862,2.635,3.028c0.737,1.166,1.239,2.531,1.462,3.945c0.464,2.839-0.562,5.855-2.32,7.912c-1.74,2.108-4.007,3.432-6.268,4.398c-2.278,0.965-4.644,1.53-7.001,1.867c-2.358,0.343-4.718,0.476-7.061,0.451c-2.34-0.014-4.676-0.191-6.99-0.545c-2.308-0.362-4.606-0.879-6.778-1.76c-2.162-0.861-4.257-2.045-5.827-3.787c-1.612-1.689-2.6-3.939-2.763-6.244c-0.202-2.297,0.273-4.663,1.478-6.652c1.2-1.997,3.067-3.535,5.118-4.653C64.145,0.974,68.926,0.267,73.572,0.04z" />
				<path fill={color} stroke="#404041" strokeMiterlimit="10" d="M10.066,19.57c-5.282,0-9.566-2.741-9.566-6.122l0,0c0-3.38,4.284-6.121,9.566-6.121h59.878c5.283,0,9.566,2.741,9.566,6.121l0,0c0,3.382-4.283,6.122-9.566,6.122H10.066z " />
				<path fill="none" stroke="#404041" strokeMiterlimit="10" d="M10.157,19.57c-5.332,0-9.657-2.741-9.657-6.122l0,0c0-3.38,4.325-6.121,9.657-6.121h60.447c5.333,0,9.657,2.741,9.657,6.121l0,0c0,3.381-4.324,6.122-9.657,6.122H10.157z" />
			</g>;
		case 'TO_RIGHT':
			return <g transform={`translate(${left},${top}) translate(0, -13.43)`}>
				<path fill={color} d="M24.937,1.067c14.307,0,20.165,5.544,20.165,12.381c0,6.838-5.858,12.381-20.165,12.381c-14.307,0-22.94-5.244-22.94-12.083C1.997,6.909,10.63,1.067,24.937,1.067z" />
				<path fill="#231F20" d="M24.937,0.04c4.646,0.227,9.427,0.934,13.586,3.175c2.05,1.118,3.917,2.656,5.118,4.653c1.204,1.99,1.68,4.355,1.478,6.652c-0.164,2.305-1.151,4.555-2.763,6.244c-1.57,1.742-3.665,2.926-5.827,3.787c-2.171,0.881-4.47,1.398-6.778,1.76c-2.313,0.355-4.649,0.531-6.99,0.545c-2.343,0.024-4.702-0.108-7.061-0.451c-2.357-0.336-4.722-0.902-7.001-1.867c-2.261-0.966-4.527-2.291-6.268-4.398c-1.758-2.056-2.783-5.073-2.32-7.912 c0.223-1.413,0.726-2.778,1.462-3.945c0.739-1.166,1.642-2.178,2.635-3.028c2.008-1.684,4.294-2.788,6.612-3.56C15.487,0.216,20.264-0.131,24.937,0.04z M24.937,2.094c-4.485,0.203-8.973,0.977-12.916,2.741c-1.956,0.878-3.78,1.976-5.222,3.334c-1.445,1.349-2.452,2.966-2.728,4.664c-0.273,1.72,0.159,3.462,1.313,4.95c1.15,1.483,2.837,2.734,4.726,3.681c3.814,1.887,8.261,2.866,12.731,3.249c4.478,0.373,9.086,0.394,13.422-0.907c2.143-0.668,4.217-1.701,5.842-3.282c1.631-1.573,2.643-3.749,2.874-6.017c0.247-2.263-0.244-4.638-1.516-6.528c-1.247-1.917-3.19-3.257-5.246-4.135c-2.078-0.876-4.307-1.319-6.538-1.566C29.447,2.02,27.188,2,24.937,2.094z" />
				<path fill={color} stroke="#404041" strokeMiterlimit="10" d="M88.443,19.57c5.282,0,9.566-2.741,9.566-6.122l0,0c0-3.38-4.284-6.121-9.566-6.121H28.565c-5.283,0-9.566,2.741-9.566,6.121l0,0c0,3.382,4.283,6.122,9.566,6.122H88.443z" />
				<path fill="none" stroke="#404041" strokeMiterlimit="10" d="M88.352,19.57c5.332,0,9.657-2.741,9.657-6.122l0,0c0-3.38-4.325-6.121-9.657-6.121H27.905c-5.333,0-9.657,2.741-9.657,6.121l0,0c0,3.381,4.324,6.122,9.657,6.122H88.352z" />
			</g>;
		case 'TO_BOTTOM':
			return <g transform={`translate(${left},${top}) translate(-13.43, 0)`}>
				<path fill={color} d="M1.067,24.937c0,14.307,5.544,20.165,12.381,20.165c6.838,0,12.381-5.858,12.381-20.165c0-14.307-5.244-22.94-12.083-22.94C6.909,1.997,1.067,10.63,1.067,24.937z" />
				<path fill="#231F20" d="M2.094,24.937C2,27.188,2.02,29.447,2.279,31.681c0.247,2.23,0.689,4.46,1.566,6.538c0.878,2.056,2.218,4,4.135,5.246c1.891,1.272,4.266,1.763,6.529,1.516c2.268-0.232,4.444-1.243,6.017-2.874c1.581-1.625,2.614-3.699,3.282-5.842c1.301-4.337,1.28-8.944,0.907-13.422c-0.383-4.471-1.363-8.917-3.249-12.731c-0.947-1.889-2.198-3.577-3.681-4.726c-1.489-1.155-3.23-1.586-4.95-1.313c-1.699,0.276-3.316,1.283-4.664,2.728c-1.358,1.442-2.457,3.266-3.334,5.222C3.071,15.964,2.297,20.452,2.094,24.937z M0.04,24.937c-0.172-4.672,0.176-9.45,1.656-14.117c0.772-2.317,1.876-4.604,3.56-6.612c0.85-0.993,1.862-1.895,3.028-2.635c1.166-0.737,2.531-1.239,3.945-1.462c2.839-0.464,5.855,0.562,7.912,2.32c2.108,1.74,3.432,4.007,4.398,6.268c0.965,2.278,1.53,4.644,1.867,7.001c0.343,2.358,0.476,4.718,0.451,7.061c-0.014,2.34-0.19,4.676-0.545,6.99c-0.362,2.308-0.879,4.606-1.76,6.778c-0.861,2.162-2.045,4.257-3.787,5.827c-1.689,1.612-3.939,2.6-6.244,2.763c-2.297,0.202-4.663-0.273-6.652-1.478c-1.997-1.2-3.535-3.067-4.653-5.118C0.974,34.364,0.267,29.583,0.04,24.937z" />
				<path fill={color} stroke="#404041" strokeMiterlimit="10" d="M19.57,88.443c0,5.282-2.741,9.566-6.122,9.566l0,0c-3.38,0-6.121-4.284-6.121-9.566V28.565c0-5.283,2.741-9.566,6.121-9.566l0,0c3.382,0,6.122,4.283,6.122,9.566V88.443z" />
				<path fill="none" stroke="#404041" strokeMiterlimit="10" d="M19.57,88.352c0,5.332-2.741,9.657-6.122,9.657l0,0c-3.38,0-6.121-4.325-6.121-9.657V27.905c0-5.333,2.741-9.657,6.121-9.657l0,0c3.381,0,6.122,4.324,6.122,9.657V88.352z" />
			</g>;
		case 'SIMPLE':
			return <g transform={`translate(${left},${top}) translate(-13.43, 0)`}>
				<path fill="none" fillOpacity="1" fillRule="nonzero" stroke="#000000" strokeOpacity="1" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" strokeDasharray="none" paintOrder="normal" d="m 37.5,-48.07107 v 100.00003" />
			</g>;
	}
	
	return null;
};

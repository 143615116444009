import _ from 'lodash';
import config from '../../config';
import {useStream} from '../../hooks/useStream';
import I18n from '../../libs/I18n';
import dataStream from '../../streams/dataStream';
import languageStream from '../../streams/languageStream';
import orderStream from '../../streams/orderStream';

let i18n = new I18n();
const {ALT_NAME_HEIGHT} = config;

const Meta = () => {
	let name = 'Okno';
	let title = 'OKNA HNED - Plastová okna na míru objednejte online';
	let description = 'Moderní a elegantní okna na míru. Např. v rozměru 1234 × 1234 mm přesně podle vašich představ. Bílá okna s šedým těsněním z kvalitního německého pětikomorového profilového systému Trocal zasklená izolačním dvojsklem Pilkington s meziskelním rámečkem a certifikáty kvality z několika zkušeben.';
	let image = 'https://www.kalkulator-oken.cz/assets/1200x630_banner.png';
	
	let width = null;
	let height = null;
	let variant = null;
	let opening = null;
	let o = 'l';
	let type = 'ST';
	
	const order = useStream(orderStream);
	const orderItem = order.currentItem === null ? undefined : order.items[order.currentItem];
	if (orderItem && orderItem.window) {
		width = orderItem.window.width;
		height = orderItem.window.height;
		
		variant = orderItem.window.hasLeftRightVariants ? orderItem.window.variant : null;
		if (variant) {
			opening = variant === 1 ? i18n.translate `windowType - variant - left` : i18n.translate `windowType - variant - right`;
			o = variant === 1 ? 'l' : 'r';
		}
		
		type = orderItem.window.type;
		
		let {windowTypes} = dataStream.value;
		let windowType = _.find(windowTypes, {id: orderItem.window.typeId});
		name = windowType.altName && orderItem.window.height >= ALT_NAME_HEIGHT ?
			windowType.altName[languageStream.value.current] :
			windowType.name[languageStream.value.current];
	} else {
		const urlSearchParams = new URLSearchParams(window.location.search);
		
		width = urlSearchParams.get('w');
		height = urlSearchParams.get('h');
		
		opening = urlSearchParams.get('o') === 'r' ? 'pravé' : 'levé';
		o = urlSearchParams.get('o') ? urlSearchParams.get('o') : 'l';
		
		type = urlSearchParams.get('t') ? urlSearchParams.get('t') : 'ST';
		name = 'Plastové okno';
	}
	
	if (width && height) {
		const wcm = width / 10;
		const hcm = height / 10;
		
		title = `${name} | ${wcm} × ${hcm} cm (${width} × ${height} mm)${opening ? ` | ${opening}` : ''}`;
		description = `Moderní a elegantní bílá okna v rozměru ${width} × ${height} mm s šedým těsněním z kvalitního německého pětikomorového profilového systému Trocal zasklená izolačním dvojsklem Pilkington s meziskelním rámečkem a certifikáty kvality z několika zkušeben.`;
		image = `https://imagegen.okna-hned.cz/?w=${width}&h=${height}${o ? `&o=${o}` : ''}&t=${encodeURIComponent(type)}&f=svg`;
	}
	
	document.title = `${title} | Kalkulátor oken – okna na míru | OKNA – HNED`;
	
	return {
		title,
		description,
		image,
		url: window.location.href,
		width,
		height,
		opening
	};
};

export default Meta;

import React from 'react';
import {Helmet} from 'react-helmet';
import Meta from './meta';

const OG = () => {
	let meta = new Meta();
	
	return (
		<Helmet>
			<meta property="og:title" content={meta.title} />
			<meta property="og:description" content={meta.description} />
			<meta property="og:image" content={meta.image} />
			<meta property="og:url" content={meta.url} />
			<meta property="og:type" content="website" />
		</Helmet>
	);
};

export default OG;

import React from 'react';

const STROKE_WIDTH = 1;

export let Arrow = ({left, orientation, view}) => {
	function isFirefox() {
		return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
	}
	
	let arrowhead = {
		strokeWidth: isFirefox() ? STROKE_WIDTH / 10 : STROKE_WIDTH,
		strokeDasharray: isFirefox() ? '0.5' : '4, 5'
	};
	
	switch (orientation) {
		case 'TO_LEFT':
			return <g transform={`translate(${left},0)`}>
				<defs>
					<marker overflow="visible" id="ArrowWideRoundedLeft" refX="0" refY="0" orient="auto-start-reverse" markerWidth="12" markerHeight="12" viewBox="0 0 1 1" markerUnits="userSpaceOnUse" preserveAspectRatio="xMidYMid">
						<path fill="none" stroke="context-stroke" strokeWidth={arrowhead.strokeWidth} strokeLinecap="butt" strokeDasharray={view === 'OUTER' ? arrowhead.strokeDasharray : 'none'} d="M 8,-8 0,0 8,8" transform="rotate(180,0.125,0)"/>
					</marker>
				</defs>
				
				<path fill="none" fillRule="evenodd" stroke="#000000" strokeWidth={STROKE_WIDTH} strokeLinecap="butt" strokeLinejoin="miter" strokeDasharray={view === 'OUTER' ? '4, 5' : 'none'} strokeOpacity="1" markerEnd="url(#ArrowWideRoundedLeft)" d="M 0,1506.3121 H 208.218" transform="rotate(180,0,0)" style={{ transformBox: 'fill-box' }} />
				<path fill="none" fillRule="evenodd" stroke="#000000" strokeWidth={STROKE_WIDTH} strokeLinecap="butt" strokeLinejoin="miter" strokeDasharray={view === 'OUTER' ? '4, 5' : 'none'} strokeOpacity="1" d="m 2,986.57875 -1.96459,519.72795"/>
			</g>;
		case 'TO_RIGHT':
			return <g transform={`translate(${left},0)`}>
				<defs>
					<marker overflow="visible" id="ArrowWideRoundedRight" refX="0" refY="0" orient="auto-start-reverse" markerWidth="12" markerHeight="12" markerUnits="userSpaceOnUse" viewBox="0 0 1 1" preserveAspectRatio="xMidYMid">
						<path fill="none" stroke="context-stroke" strokeWidth={arrowhead.strokeWidth} strokeLinecap="butt" strokeDasharray={view === 'OUTER' ? arrowhead.strokeDasharray : 'none'} d="M 8,-8 0,0 8,8" transform="rotate(180,0.125,0)"/>
					</marker>
				</defs>
				
				<path fill="none" fillRule="evenodd" stroke="#000000" strokeWidth={STROKE_WIDTH} strokeLinecap="butt" strokeLinejoin="miter" strokeDasharray={view === 'OUTER' ? '4, 5' : 'none'} strokeOpacity="1" markerEnd="url(#ArrowWideRoundedRight)" d="M 0,1506.3121 H 208.218"/>
				<path fill="none" fillRule="evenodd" stroke="#000000" strokeWidth={STROKE_WIDTH} strokeLinecap="butt" strokeLinejoin="miter" strokeDasharray={view === 'OUTER' ? '4, 5' : 'none'} strokeOpacity="1" d="m 2,986.57875 -1.96459,519.72795"/>
			</g>;
	}
	
	return null;
};

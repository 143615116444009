/* eslint-disable complexity */

import _ from 'lodash';
import React, {useState, useCallback, useRef, useEffect} from 'react';

import {Window} from './Window';
import styles from './WindowVisualization.css';
import dataStream from '../streams/dataStream';
import I18n from '../libs/I18n';

let i18n = new I18n();

export default function WindowVisualization({orderItem}) {
	let [sizeInteriorView, setSizeInteriorView] = useState({
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
		x: 0,
		y: 0
	});
	let [sizeExteriorView, setSizeExteriorView] = useState({
		bottom: 0,
		height: 0,
		left: 0,
		right: 0,
		top: 0,
		width: 0,
		x: 0,
		y: 0
	});

	let interiorViewElement = useRef(null);
	let exteriorViewElement = useRef(null);

	let handleResizeInteriorView = useCallback((frameSize) => {
		if (interiorViewElement) {
			let size = interiorViewElement.current.getBoundingClientRect();

			setSizeInteriorView({
				left: frameSize.left - size.left,
				top: frameSize.top - size.top,
				width: frameSize.width,
				height: frameSize.height
			});
		}
	}, []);

	let handleResizeExteriorView = useCallback((frameSize) => {
		if (exteriorViewElement) {
			let size = exteriorViewElement.current.getBoundingClientRect();

			setSizeExteriorView({
				left: frameSize.left - size.left,
				top: frameSize.top - size.top,
				width: frameSize.width,
				height: frameSize.height
			});
		}
	}, []);

	if (!orderItem || !orderItem.window) {
		return null;
	}

	let colorValue;
	let {colors} = dataStream.value;
	let color;
	let glassColorInner;
	let glassColorOuter;

	if (orderItem.color) {
		color = _.find(colors, {id: orderItem.color.id});
		colorValue = color ? color.value : '#ffffff';
	}

	if (orderItem.glass && (orderItem.glass.id === 9 || orderItem.glass.id === 17)) {
		glassColorInner = '#ffffff';
		glassColorOuter = '#ffffff';
	}

	if (orderItem.glass && (orderItem.glass.id === 10 || orderItem.glass.id === 18)) {
		glassColorInner = '#ffffff';
		glassColorOuter = colorValue;
	}

	if (orderItem.glass && (orderItem.glass.id === 11 || orderItem.glass.id === 19)) {
		glassColorInner = colorValue;
		glassColorOuter = colorValue;
	}

	let {type} = orderItem.window;
	let {variant} = orderItem.window;
	let ratio = orderItem.window.height / orderItem.window.width;

	// console.log('orderItem', orderItem);
	// console.log(orderItem.glass.name);
	// console.log(
	//   colorValue,
	//   glassColor,
	//   glassColor && color && orderItem.color && orderItem.color.sidesCount >= 2
	//     ? glassColor
	//     : glassColor
	//     ? '#ffffff'
	//     : undefined,
	// );

	return (
		<div className={styles.root + (ratio >= 1 ? ` ${styles.isTall}` : '')}>
			{orderItem && orderItem.window && orderItem.window.typeId ?
				<figure ref={interiorViewElement} className={styles.window}>
					<Window
						width={orderItem.window.width}
						height={orderItem.window.height}
						color={color && orderItem.color && orderItem.color.sidesCount >= 2 ? colorValue : '#ffffff'}
						glassColor={glassColorInner}
						type={type}
						variant={variant}
						view="INNER"
						onResize={handleResizeInteriorView}
					/>
					<figcaption>
						<span className={styles.view}>{i18n.translate `Main - visualisation - interior view`}</span>
						<span style={{left: sizeInteriorView.left + sizeInteriorView.width * 0.5, top: sizeInteriorView.top}} className={styles.width}>
							{orderItem.window.width} mm
						</span>
						<span style={{left: sizeInteriorView.left - 10, top: sizeInteriorView.top + sizeInteriorView.height * 0.5}} className={styles.height}>
							{orderItem.window.height} mm
						</span>
					</figcaption>
				</figure> :
				null }
			
			{orderItem && orderItem.window && orderItem.window.typeId ?
				<figure ref={exteriorViewElement} className={styles.window}>
					<Window
						width={orderItem.window.width}
						height={orderItem.window.height}
						color={colorValue}
						glassColor={glassColorOuter}
						type={type}
						variant={variant}
						view="OUTER"
						onResize={handleResizeExteriorView}
					/>
					<figcaption>
						<span className={styles.view}>{i18n.translate `Main - visualisation - exterior view`}</span>
						<span style={{left: sizeExteriorView.left + sizeExteriorView.width * 0.5, top: sizeExteriorView.top}} className={styles.width}>
							{orderItem.window.width} mm
						</span>
						<span style={{left: sizeExteriorView.left + sizeExteriorView.width + 10, top: sizeExteriorView.top + sizeExteriorView.height * 0.5}} className={styles.height}>
							{orderItem.window.height} mm
						</span>
					</figcaption>
				</figure> :
				null }
		</div>
	);
}
